import React from "react";

import { faChevronLeft } from "@fortawesome/sharp-regular-svg-icons/faChevronLeft";
import { faUserCircle } from "@fortawesome/sharp-regular-svg-icons/faUserCircle";
import { faBagShopping } from "@fortawesome/sharp-solid-svg-icons/faBagShopping";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { SvgLogo } from "~components/SvgLogo";
import { HideChildren } from "~components/VisibilityCheck/HideChildren";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { MockUserAvatar } from "~animations/mock_ui/AnimMockUiVerification1/MockUserAvatar";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";
import * as styles from "./styles.css";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton/types";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

const ICON_MAP = {
  faUserCircle,
  faChevronLeft,
  faBagShopping,
} as const;

type IconMapKey = keyof typeof ICON_MAP;

interface AnimLayerUiTopNavProps extends BoxProps {
  title?: string;
  iconLeft?: IconMapKey;
  iconRight?: IconMapKey;
  brandLogo?: string; // TODO: implement in CMS
  avatar?: boolean;
  avatarBgColor?: string;
  animLayerFakeButton?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  brandLogoPosition?: "left" | "center";
}

export function AnimLayerUiTopNav({
  iconLeft,
  iconRight,
  title,
  brandLogo,
  avatar,
  avatarBgColor,
  brandLogoPosition = "center",
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  ...rest
}: AnimLayerUiTopNavProps) {
  const dynamicIconRight = iconRight && (ICON_MAP[iconRight] as IconDefinition);
  const dynamicIconLeft = iconLeft && (ICON_MAP[iconLeft] as IconDefinition);
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];

  return (
    <Box
      display="grid"
      __gridTemplateColumns="1fr auto 1fr"
      gap="phoneSpacing2"
      alignItems="center"
      padding="phoneSpacing2"
      {...rest}
    >
      <ShowChildren when={dynamicIconLeft}>
        <IconFontAwesome
          icon={dynamicIconLeft!}
          size="sm"
          padding="phoneSpacing1"
          width="icon_md"
          height="icon_md"
        />
      </ShowChildren>
      <HideChildren when={dynamicIconLeft || brandLogoPosition !== "center"}>
        <div />
      </HideChildren>

      {/* Optional title and brandLogo */}
      <ShowChildren when={title}>
        <Box
          textAppearance="phone_text_lg"
          fontWeight="semibold"
          textAlign="center"
        >
          {title}
        </Box>
      </ShowChildren>
      <ShowChildren when={brandLogo}>
        <SvgLogo
          id="AnimLayerUiTopNavLogo"
          svgLogo={brandLogo!}
          marginRight="auto"
          marginLeft={brandLogoPosition === "center" ? "auto" : "none"}
          height="image_md"
        />
      </ShowChildren>
      <HideChildren when={title || brandLogo}>
        <div />
      </HideChildren>

      {/* Optional dynamicIconRight and ctaButton */}
      <ShowChildren when={dynamicIconRight}>
        <IconFontAwesome
          marginLeft="auto"
          padding="phoneSpacing1"
          width="icon_md"
          height="icon_md"
          borderRadius="50%"
          icon={dynamicIconRight!}
          className={styles.icon}
          size="sm"
          aspectRatio="square"
        />

        <ShowChildren when={!!avatar}>
          <MockUserAvatar backgroundColor={avatarBgColor || "#000"} />
        </ShowChildren>
      </ShowChildren>
      <ShowChildren when={animLayerFakeButton}>
        <div />
        <AnimLayerFakeButton
          size="sm"
          width="phoneSpacing14"
          marginLeft="auto"
          variant="secondary"
          {...animLayerFakeButton}
        />
      </ShowChildren>
      <HideChildren when={dynamicIconRight || animLayerFakeButton}>
        <div />
      </HideChildren>
    </Box>
  );
}
